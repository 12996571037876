import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSponsorInfo } from "../../services/sponsors/sponsorsReq";
import styled from 'styled-components';

import SponsorImageComponent from '../sponsorFormElements/sponsorImage'
import SponsorNameComponent from '../sponsorFormElements/sponsorName'
import ButtonsArea from '../sponsorFormElements/buttonsArea'

import ModalNotification from "../modals/modalNotification";
import SponsorDescription from "../sponsorFormElements/sponsorDescription";
import SponsorType from "../sponsorFormElements/sponsorType";


interface widgetProps {
    type: string
    handleSave: Function,
    handleDelete?: Function
};

export interface TSponsorData {
    id?: string,
    SponsorName: string,
    SponsorImage: string,
    Description: string,
    Date: any,
    Status: boolean,
    Type: string
}


const emptySponsor: TSponsorData = {
    SponsorImage: "",
    SponsorName: "",
    Description: "",
    Date: "",
    Status: false,
    Type: ""
}

interface TSponsorImageData {
    url: string,
    file: any
}
export interface TImageFiles {
    SponsorImageData: TSponsorImageData
}

const emptyImageFiles = {
    SponsorImageData: {
        url: "",
        file: undefined
    }
}

const requiredFields: Array<string> = ["SponsorName", "Description", "Type"]
const errorMesages: Object = {
    "SponsorName": "Write a Name for this Sponsor",
    "Description": "Add a Description for the Sponsor",
    "Date": "Write a date for the Sponsor",
    "Status": "Set the current status for the Sponsor",
}

const checkRequired = (sponsor: TSponsorData) => {
    let activateAlert = { state: false, element: "" };
    let newSponsor: any = { ...sponsor }
    for (let key in sponsor) {
        if (requiredFields.includes(key)) {
            if (!newSponsor[key] || !newSponsor[key].length) {
                activateAlert.state = true;
                activateAlert.element = key;
                break;

            }
        }
    }
    return activateAlert;
}

const emptyOptions = {
    "type": [{
        name: "car manufacturer",
        active: true
    },{
        name: "technology manufacturer",
        active: true
    },{
        name: "other manufacturer",
        active: true
    }]
}
const emptyModal = {
    acceptText: "",
    cancelText: "",
    closeModal: () => {},
    confirmModal: () => {},
    message: ""
}


export default function SponsorForm(props: widgetProps) {

    let [imageFiles, setImageFiles] = useState<TImageFiles>(emptyImageFiles)
    let [sponsorData, setSponsorData] = useState(emptySponsor);
    let [alert, setAlert] = useState({ state: false, element: "" });
    let [dropMenuOption, setDropMenuOptions] = useState<{ type: any }>(emptyOptions);
    let [modalInfo, setModalInfo] = useState(emptyModal)
    let [confirmationModalState, setConfirmationModalState] = useState(false);
    let navigate = useNavigate();


    let [typeFlag, setTypeFlag] = useState(false)
    let updateTypeFlag = () => setTypeFlag(prevState => !prevState)

    let { id } = useParams()

    useEffect(() => {
        console.log(imageFiles)
    }, [imageFiles])

    useEffect(() => {
        setImageFiles(emptyImageFiles)
        setAlert({ state: false, element: "" })

        if ((props.type === "edit") && id) {
            getSponsorInfo(id).then((response: TSponsorData) => {
                //localGetSponsorInfo(id).then((response: TSponsorData) => {
                let initialImagesData = {
                    SponsorImageData: {
                        url: response.SponsorImage,
                        file: undefined
                    }
                }
                //response.Validated = true;
                setSponsorData(response);
                setImageFiles(initialImagesData);
                console.log(response)
                
            }).catch((error) => { console.log("error: " + error) })
        }

        //=== drop menu options

    }, []);



    const updateSponsorAttributes = (property: string, value: string | Array<string> | boolean) => {
        setSponsorData((prevState: TSponsorData) => {
            let newState: TSponsorData | any = { ...prevState };
            newState[property] = value;
            return newState;
        })
    }


    // Reset dropmenu state when clicking outside
    const resetDropStates = (sponsor: any) => {
        let clickedCurrentInputOptions = sponsor.target.classList.value.match(/(?<=option-)\d+|(?<=selection-)\d+/);
        if (!clickedCurrentInputOptions) {
            if (typeFlag) {
                setTypeFlag(false);
            }
        }
    }


    const confirmSaving = () => {
        setConfirmationModalState(false)
        setAlert({ state: false, element: "" })
       
        let temporalDate = new Date(sponsorData.Date.replace(/-/g, "/"));
        let timeMillisDate = temporalDate.getTime();
        let updatedSponsorData = { 
            ...sponsorData,
            Date: timeMillisDate
        };
        let saveParameters = {
            idSponsor: id,
            sponsorData: updatedSponsorData,
            sponsorImageFiles: imageFiles,
        }
        props.handleSave(saveParameters)
        console.log(saveParameters)
    }

    const confirmDelete = () => {
        if (props.handleDelete){
        setConfirmationModalState(false)
        setAlert({ state: false, element: "" })
        props.handleDelete(id)
        }
    }


    //===== Buttons
    const handleSave = () => {
        const modalConfirmationInfo = {
            acceptText: "Save",
            cancelText: "Cancel",
            closeModal: () => { setConfirmationModalState(false) },
            confirmModal: confirmSaving,
            message: "Are you sure to save this sponsor?"
        }
        setModalInfo(modalConfirmationInfo)
        let activateAlert = checkRequired(sponsorData);
        setAlert(activateAlert);
        if (!activateAlert.state) {
            setConfirmationModalState(true);
        }
    }

    const handleDelete = () => {
        if (props.handleDelete){
        const modalDeleteInfo = {
            acceptText: "Delete",
            cancelText: "Cancel",
            closeModal: () => { setConfirmationModalState(false) },
            confirmModal: confirmDelete,
            message: "Are you sure to delete this sponsor?"
        }
        setModalInfo(modalDeleteInfo);
        setConfirmationModalState(true);
    }
    }

    const handleCancel = () => { navigate("/sponsors") };
   

    return (
        <FormSponsor onClick={resetDropStates}>
            {confirmationModalState && <ModalNotification {...modalInfo} />}
            <Title><h2>{props.type === "edit" ? "EDIT" : 'CREATE'} EVENT</h2></Title>
            <RequiredAdd> <p><PurpleDot /> Required fields </p></RequiredAdd>
            <SponsorImageComponent sponsorData={sponsorData} updateSponsorAttributes={updateSponsorAttributes} updateImageFiles={(data: TImageFiles) => setImageFiles(data)} imageFiles={imageFiles} />
            <SponsorDescription sponsorData={sponsorData} updateSponsorAttributes={updateSponsorAttributes} />
            <SponsorNameComponent sponsorData={sponsorData} updateSponsorAttributes={updateSponsorAttributes} />
            <SponsorType sponsorData={sponsorData} updateSponsorAttributes={updateSponsorAttributes} typeFlag={typeFlag} updateTypeFlag={updateTypeFlag} dropOptions={dropMenuOption || []} />
            {alert.state && <ErrorMessage>*{`${errorMesages[alert.element as keyof Object]}`}</ErrorMessage>}

            <ButtonsArea sponsorData={sponsorData} type={props.type} handleSave={handleSave} handleDelete={handleDelete} handleCancel={handleCancel} />

        </FormSponsor>
    );
}



const FormSponsor = styled.div`
    box-sizing: border-box;
    padding: 40px 20px 40px 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 20px;
    width: 100%;
    max-width: 900px;
`

const Title = styled.div`
    & h2{
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        text-transform: uppercase;
        color: #412ea1;
        margin: 0;
    }
`

const RequiredAdd = styled.div`
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    & p{
        margin: 0;
    }
`

const PurpleDot = styled.span`
    &::before{
        color: #412ea1;
        content: "*";
    }
`

const Draft = styled.div`
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: flex-end;
    gap: 18px;
`
const DraftText = styled.p<{ state: boolean }>`
    color: ${({ state }) => state ? "#84004C" : "#9B9B9B"};
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 26.5497px;
    line-height: 32px;
    margin: 0px;
`

const ErrorMessage = styled.p`
    margin: 0;
    color: #c71111;
    flex: 1;
    text-align: left;
`
const SuccessMessage = styled.p`
    margin: 0;
    color: #12c71b;
    flex: 1;
    text-align: left;
`