import React, {useState} from "react";
import styled from 'styled-components'

interface widgetProps { state: boolean, size: string, changeState: Function }
export default function SwitchButton2( props: widgetProps ) {
    const changeState = () => {props.changeState()}
    return(
        <SwitchButtonMain state={props.state} size={props.size} onClick={changeState}>
                <CircleSwitch state={props.state} size={props.size}/>
        </SwitchButtonMain>
    );
}

const SwitchButtonMain = styled.div<{state:boolean, size:string, normal?:boolean}>`
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: ${({state})=> state? "#84004C": "#DADADA"};
    border-radius: ${({size})=> +size*0.6 + "px"};;
    width: ${({size})=> +size*2 + "px"};
    height: ${({size})=> size + "px"};
    padding: 1px;
    cursor: pointer;
    //transition: all 1s linear;
    //margin: auto;
    background: ${({state})=> state? "#84004C": "#DADADA"};
`

const CircleSwitch = styled.div<{state:boolean, size:string}>`
    position: absolute;
    background: #FFFFFF;
    border-radius: 50%;
    width: ${({size})=> size + "px"};
    height: ${({size})=> size + "px"};
    right: ${({state})=> state? "1px": "50%"};
    transition: all 0.2s linear;
`