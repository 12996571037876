import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectUserInfo } from '../../services/user/userSlice';

import './sidebar.css'

import { NavLink } from "react-router-dom";

import LogoIcon from "../../assets/icons/foundXLogo.png"
import SponsorIcon from "../../assets/icons/sponsor.png"
import LogoutIcon from "../../assets/icons/logout.png"
import { useLocation, } from "react-router-dom";


function Sidebar() {
  const userInfo = useAppSelector(selectUserInfo);
  const sampleLocation = useLocation();

  /*useLayoutEffect(() => {
    console.log(sampleLocation.pathname)
  }, [sampleLocation.pathname]);*/

  // useLayoutEffect(() => {
  //     console.log('Sidebar Reload userInfo')
  //     console.log(userInfo)
  // }, [userInfo]);

  const logOutHandler = () => {
    console.log("logout required")
  }

  return (
    <div className="SideBar">
      <div className='sidebar-upper'>

        <div className='sidebar-header'>
          <img className="sidebar-header-image" src={LogoIcon} alt="FoundXLogo" />
        </div>

        <div className='sidebar-navigation'>
          <NavLink to="/sponsors">
            <div className={sampleLocation.pathname === '/sponsors' ? 'sidebar-option sidebar-option-active' : 'sidebar-option'} >
              <img
                className='sidebar-option-icon'
                src={SponsorIcon}
                alt='sidebar-option-icon'
              />
              <div className='sidebar-option-title'>Sponsors</div>
            </div>
          </NavLink>
        </div>

      </div>


      <div className='sidebar-footer'>
        <div className='sidebar-ruler endRuler' />
        <div className='sidebar-userinfo'>

          <div className='sidebar-userinfo-container'>
            <p className='sidebar-userinfo-username' >
              {userInfo.data ? `@${userInfo.data.username}` : 'loading...'}
            </p>
            <p className='sidebar-userinfo-email' >
              {userInfo.data ? userInfo.data.email : 'loading...'}
            </p>
          </div>

          <div className="sidebar-userinfo-logo" >
            <img src={LogoutIcon} alt="logout" onClick={logOutHandler}/>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Sidebar;
