import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SponsorForm from "../../components/sponsorsList/sponsorForm";
import { updateSponsorInfo, uploadSponsorFoto, deleteSponsor } from "../../services/sponsors/sponsorsReq"
import { TSponsorData, TImageFiles } from "../../components/sponsorsList/sponsorForm";

interface TfunctionParams {
    idSponsor?: any,
    sponsorData: TSponsorData,
    sponsorImageFiles: TImageFiles,
}

export default function EditSponsor() {
    let navigate = useNavigate();

    const handleSave = (parameters: TfunctionParams) => {
        let { idSponsor, sponsorData, sponsorImageFiles } = parameters
        let sponsorImageFile = sponsorImageFiles.SponsorImageData.file;

        let payloadCreate = {
            "sponsor_name": sponsorData.SponsorName,
            "desc": sponsorData.Description,
            "tipo": sponsorData.Type,
        }

        
        //==== Adding new sponsor image
        if (sponsorImageFile) {
            let sponsorImageType =  sponsorImageFile.type.split("/").pop();
            let sponsorFotoData = {
                id: idSponsor, 
                file_type: sponsorImageType, 
                image_type:  "main", 
                files: sponsorImageFile
            }
            uploadSponsorFoto(sponsorFotoData);
        }

        //==== Updating sponsor specs
        let sponsorDataPayload = {
            sponsorId: String(idSponsor),
            data: payloadCreate
        }
        console.log(sponsorDataPayload)
        updateSponsorInfo(sponsorDataPayload).then((response:any) => {
            navigate("/sponsors");
        })
    }

    const handleDelete = (id: string) => {
        deleteSponsor({ data: id }).then((response) => {
            console.log(response);
            navigate("/sponsors")
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <SponsorForm type="edit" handleSave={handleSave} handleDelete={handleDelete}/>
    );
}