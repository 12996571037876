import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../services/counter/counterSlice';
import userReducer from '../services/user/userSlice';
import sponsorsReducer from '../services/sponsors/sponsorsSlice'
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux"
import popUp from '../components/popUp/popUpSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    sponsors: sponsorsReducer,
    popUp
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.bannerImage', 'payload.logoImage', 'payload.additionalImages', 'payload.tokenImages'],
        ignoredPaths: ['imagesCollection'],
      },
    }),

});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector