import React, { useEffect } from "react";
import SponsorForm from "../../components/sponsorsList/sponsorForm";
import { useNavigate } from "react-router-dom";
import { createSponsorInfo, TResponseCreate, uploadSponsorFoto } from "../../services/sponsors/sponsorsReq"
import { AxiosResponse } from 'axios';
import { TSponsorData, TImageFiles } from "../../components/sponsorsList/sponsorForm";
import { useAppDispatch } from "../../app/hooks";
import { setLogEvent } from "../../components/popUp/popUpSlice";

interface TfunctionParams {
    idSponsor?: any,
    sponsorData: TSponsorData,
    sponsorImageFiles: TImageFiles,
}

export default function NewSponsor(props: any) {
    let navigate = useNavigate();
    const dispatch = useAppDispatch()

    const handleSave = (parameters: TfunctionParams) => {
        let { sponsorData, sponsorImageFiles } = parameters;
        let sponsorImageFile = sponsorImageFiles.SponsorImageData.file;
        
        let payloadCreate = {
            "type": "create",
            "sponsor_name": sponsorData.SponsorName,
            "desc": sponsorData.Description,
            "tipo": sponsorData.Type
        }
        
        createSponsorInfo({ data: payloadCreate }).then((response: AxiosResponse<TResponseCreate>) => {
            let newSponsorId = response.data['sponsor_id'];
            
            //==== Adding new images
            if (sponsorImageFile) {
                let sponsorImageType = sponsorImageFile.type.split("/").pop();
                let sponsorFotoData = {
                    id: newSponsorId, 
                    file_type: sponsorImageType, 
                    image_type: "main", 
                    files: sponsorImageFile
                }
                uploadSponsorFoto(sponsorFotoData);
            }

            navigate("/sponsors");

            
        }).catch((error: any) => {
            console.log(error)
            dispatch(setLogEvent({type: 'error', message: error.response.data.message }))
        });
    }

    return (
        <div className="newCollection">
            <SponsorForm type="new" handleSave={handleSave} />
        </div>
    );
}