import React, {useState} from "react";
import { SectionForm, SectionTitle, PurpleDot, TextInputArea } from './sponsorStylingForm'
import {TSponsorData} from '../sponsorsList/sponsorForm'

interface widgetProps { sponsorData: TSponsorData, updateSponsorAttributes: Function }
export default function SponsorName( props: widgetProps ) {
    let { sponsorData, updateSponsorAttributes } = props;
    let changeSponsorName = (event: any) => { updateSponsorAttributes("SponsorName", event.target.value) }
    return(
        <SectionForm>
                <SectionTitle>Sponsor Name <PurpleDot /></SectionTitle>
                <TextInputArea type="text" value={sponsorData.SponsorName} onChange={changeSponsorName} withLogo={false} disabled={sponsorData.Status} />
            </SectionForm>
    );
}