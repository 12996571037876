import React, {useState} from "react";
import { SectionForm, SectionTitle, DescriptionText, PurpleDot } from './sponsorStylingForm'

interface widgetProps { sponsorData: any, updateSponsorAttributes: Function }
export default function SponsorDescription( props: widgetProps ) {
    let { sponsorData, updateSponsorAttributes } = props;
    let changeDescription = (event: any) => { updateSponsorAttributes("Description", event.target.value) }
    return(
        <SectionForm>
                <SectionTitle>Description <PurpleDot /></SectionTitle>
                <DescriptionText value={sponsorData.Description} onChange={changeDescription} rows={10} disabled={sponsorData.Status} />
            </SectionForm>
    );
}