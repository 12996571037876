import { AnyAction } from "@reduxjs/toolkit";
import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import SponsorList from "../../components/sponsorsList/sponsorList";
import { getApiSponsors, getApiSponsorsStatus, getApiSponsorsErrors, fetchSponsors} from '../../services/sponsors/sponsorsSlice'
import "./Sponsors.css"
import PlusImage from '../../assets/icons/plus.png';
import { NavLink } from "react-router-dom";

interface widgetProps { identifier?: string }
export default function Sponsors( props: widgetProps ) {
    const dispatch = useAppDispatch();
    const sponsors = useSelector(getApiSponsors);
        
    useEffect(()=>{dispatch(fetchSponsors());}, [])

    return(
        <div className="sponsorApp">
            <div className="sponsor-main">
                <div className="sponsors-main__NewSponsor">
                    <NavLink to="/sponsors/new"  className="newSponsor-button">
                        <h3 className="newSponsor-buttonTitle">Add New Sponsor </h3>
                        <img src={PlusImage} alt="plusButton" className="newSponsor-btnImg" />
                    </NavLink>
                </div>

                <div className="sponsors-main__ListSponsor">
                    <SponsorList apiSponsors={sponsors}/>
                </div>
            </div>
        </div>
    );
}