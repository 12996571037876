import React, { useEffect, useLayoutEffect, useState } from 'react'
import logo from './logo.svg'
import Cookies from 'universal-cookie'

import { useAppSelector, useAppDispatch } from '../app/hooks'
import { requestUserInfo, selectUserInfo } from '../services/user/userSlice'

import { Route, Routes, useNavigate } from "react-router-dom"
import './App.css'
import '../assets/css/font-family.css'

import Sidebar from '../components/sidebar/sidebar'
import Sponsors from '../views/sponsors/Sponsors'
import Users from '../views/usersManagement/Users'
import Verifying from '../views/Verifying'

import { useLocation, } from "react-router-dom"
import NewSponsor from '../views/sponsors/NewSponsor'
import EditSponsor from '../views/sponsors/EditSponsor'

import PopUp from '../components/popUp/popUp'



const cookies = new Cookies();

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo = useAppSelector(selectUserInfo);
  const sampleLocation = useLocation();
  const [islogged, setIslogged] = useState(typeof cookies.get('vrc_admin') !== 'undefined');

  useLayoutEffect(() => {
    setIslogged(typeof cookies.get('vrc_admin') !== 'undefined')
  }, [userInfo])

  useLayoutEffect(() => {
    dispatch(requestUserInfo());
  }, [sampleLocation.pathname]);


  if (islogged) {
    return (
      <div className="App">

        <div className='sidebar' >
          <Sidebar />
        </div>

        <div className='main'>
          <Routes>
            <Route path="/" element={<Sponsors />} />
            <Route path="/users" element={<Users />} />
            <Route path="/sponsors" element={<Sponsors />} />
            <Route path="/sponsors/new" element={<NewSponsor />} />
            <Route path="/sponsors/:id-item" element={<EditSponsor />} />
          </Routes>
        </div>

        <PopUp/>

      </div>
    );

  } else {
    if (window.location.pathname !== '/') {
      navigate("/");
    }

    return (
      <div className="App">
        <div className="main">
          <Routes>
            <Route path="/" element={<Verifying />} />
          </Routes>
        </div>
      </div>
    )
  }
}

export default App;
