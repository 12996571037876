import React, { useState } from "react";
import "./SponsorCard.css"
import BlackEdit from "../../assets/icons/penBlack.png"
import { NavLink } from "react-router-dom";
import { EmptyImageContainer } from '../sponsorFormElements/sponsorStylingForm';
import DefaultSponsor from '../../assets/icons/defaultSponsor.png';

interface TSponsorCard {
    cardInfo: {
        id: string,
        SponsorName: string,
        SponsorImage: string,
        Description: string,
        Type: string,
    }
}
export default function SponsorCard(props: TSponsorCard) {
    const { id, SponsorName, SponsorImage, Description, Type } = props.cardInfo;
    let normalMountain = (
        <EmptyImageContainer clickable={false}>
            <img src={DefaultSponsor} alt="EmptyImage" width="128px" height="128px" />
        </EmptyImageContainer>
    )

    let sponsorImageSource = <img src={SponsorImage} alt={SponsorName} className="cardImage" />
    let dynamicTypeText = Type.length > 20? Type.slice(0,20) + "..." : Type;
    let dynamicDescriptionText = Description.length > 20? Description.slice(0,20) + "..." : Description;
    return (
        <div className="sponsorCard">
            <div className="sponsorCard-Image">
                    {SponsorImage ? sponsorImageSource : normalMountain}
                    <NavLink to={`/sponsors/${id}-item`} className="cardEdit">
                        <img src={BlackEdit} alt="editButton" className="cardEdit-image" />
                    </NavLink>
            </div>
            <div className="sponsorCard-Info">
                <p>{SponsorName}</p>
                <p>Type: {dynamicTypeText}</p>
                <p>Description: {dynamicDescriptionText}</p>
            </div>
        </div>
    );
}