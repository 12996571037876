import React, { useState } from "react";
import "./SponsorList.css"
import SponsorCard from "./sponsorCard";

interface TSponsorCard {
    id: string,
    SponsorName: string,
    SponsorImage: string,
    Description: string,
    Type: string
}
interface TSponsorList { apiSponsors: Array<any> }
export default function SponsorList(props: TSponsorList) {

    let listSponsors = props.apiSponsors ? props.apiSponsors.map((cardInfo: TSponsorCard, index: number) => {
        return (
            <SponsorCard cardInfo={cardInfo} key={String(index)} />
        )
    }) : '';

    return (
        <div className="sponsorListApp">
            {listSponsors}
        </div>
    );
}