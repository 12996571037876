import axios, { AxiosResponse } from 'axios';
import { app } from '../../config/config'
import { TSponsorData } from '../../components/sponsorsList/sponsorForm';
import SponsorList from '../../components/sponsorsList/sponsorList';
let BACKEND_URL = app.BACKEND_URL;

function formatDate(date: number) {
    let newDate = new Date(date);
    let day:any = newDate.getDate();
        day = day < 10? `0${day}`: day;
    let month:any = +newDate.getMonth() + 1;
        month = month < 10? `0${month}`: month;
    let year = newDate.getFullYear();
    return `${day}/${month}/${year}`;
}


let sampleData:any = [];

export interface TSponsorFormated {
    id: string,
    SponsorName: string,
    SponsorImage: string,
    Category: string,
    SponsorAddress: string
}
interface SponsorsState {
    registered_by: string,
    tipo: string,
    id_item: string,
    createdAt: number,
    sponsor_name: string,
    desc: string,
    main_image?: string 
}

export async function sponsorsRequest() {
    const axiosOptions = {
        url: `${BACKEND_URL}/admin/sponsors`,
        method: "GET",
        withCredentials: true
    }

    return new Promise<{ data: Array<SponsorsState> }>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                const data = response.data.items.map((element: SponsorsState) => {
                    //console.log(element)
                    return {
                        id: element.id_item,
                        SponsorName: element.sponsor_name,
                        SponsorImage: element.main_image || "",
                        Description: element.desc,
                        Type: element.tipo
                    }
                })
                resolve({ data })
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}

export async function localSponsorsRequest() {
    return new Promise<{ data: Array<any> }>((resolve, rejects) => {
        resolve(sampleData)
    })
}



function formatSponsorApiToLocal<TSponsorData>(data: any) {
    let convertedDate = data.date? formatDate(data.date) : "";
    if(convertedDate){
        let [day, month, year] = convertedDate.split("/");
        convertedDate = `${year}-${month}-${day}`
    }
    console.log(data)
    let sponsorFormated = {
        SponsorImage: data.main_image || "",
        SponsorName: data.sponsor_name,
        Description: data.desc,
        Date: convertedDate,
        Status: data.status==="launched" || false,
        Type: data.tipo||""
    }
    return sponsorFormated
}

export function getSponsorInfo(sponsorId: string) {
    const axiosOptions = {
        url: `${BACKEND_URL}/admin/sponsors/${sponsorId}`,
        method: "GET",
        withCredentials: true
    }

    return new Promise<TSponsorData>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                const data: TSponsorData = formatSponsorApiToLocal(response.data)
                resolve(data)
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}


interface TUpdateSponsorInfo {
    sponsorId: string,
    data: {
        "sponsor_name": string,
        "desc": string,
        "tipo": string
    }
}
export function updateSponsorInfo({ sponsorId, data }: TUpdateSponsorInfo) {
    const axiosOptions = {
        url: `${BACKEND_URL}/admin/sponsors/${sponsorId}`,
        method: "PUT",
        data: data,
        withCredentials: true
    }

    return new Promise<{ data: Array<SponsorsState> }>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}


export interface TResponseCreate {
    sponsor_id: string,
    result: string
}
interface TCreateSponsorInfo {
    data: {
        "type": string
        "sponsor_name": string,
        "desc": string,
        "tipo": string
    }
}
export function createSponsorInfo({ data }: TCreateSponsorInfo) {
    const axiosOptions = {
        url: `${BACKEND_URL}/admin/sponsors`,
        method: "POST",
        withCredentials: true,
        data: data
    }

    return new Promise<AxiosResponse<TResponseCreate>>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                rejects(error)
            })
    })
}


interface TDeleteSponsor {
    data: any
}
export function deleteSponsor({ data }: TDeleteSponsor) {
    
    const axiosOptions = {
        method: 'DELETE',
        withCredentials: true,
        url: `${BACKEND_URL}/admin/sponsors/${data}`
    };

    return new Promise<AxiosResponse<any>>((resolve, reject) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                // handle error
                console.log(error)
                reject(error)
            })
    })
}



interface TgetPresignedUrlProps {
    sponsor_id: string,
    file_type: string,
    image_type: string,
    token_info?: any
}
interface TUploadSponsorFoto {
    id: string | undefined,
    file_type: string,
    image_type: string,
    files: any
}

export function uploadSponsorFoto(data: TUploadSponsorFoto) {
    const { id, file_type, image_type, files } = data
    const sponsor_id = id;


    function getPresignedUrl(props: TgetPresignedUrlProps) {
        const axiosOptions = {
            url: `${BACKEND_URL}/admin/sponsors`,
            method: 'POST',
            data: {
                type: "uploadImage",
                ...props,
            },
            withCredentials: true,
        }

        return new Promise<AxiosResponse<any>>((resolve, reject) => {
            axios(axiosOptions)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                    reject('No possible to update inspection')
                })
        })
    }


    function updateInspeccion(toUpdate: any) {
        const axiosOptions = {
            method: 'POST',
            data: {
                type: "updateInspeccion",
                ...toUpdate,
            },
            withCredentials: true,
            url: `${BACKEND_URL}/admin/sponsors`
        };

        return new Promise<AxiosResponse<any>>((resolve, reject) => {
            axios(axiosOptions)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                    reject('No possible to update inspection')
                })
        })
    }


    function uploadImage(file: File | Blob, slug: string, type: string) {

        return new Promise<any>((resolve, reject) => {
            fetch(slug, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                body: file
            })
                .then(data => {
                    resolve({ result: 'success' })
                })
                .catch((error) => console.log('userInfo error', error));
        })
    }



    if (sponsor_id && file_type) {
        
        getPresignedUrl({ sponsor_id, file_type, image_type })
            .then((resp: any) => {
                
                uploadImage(files, resp.urlS3Image, file_type)
                    .then(() => {

                        updateInspeccion({ task_id: sponsor_id, [`${image_type}`]: resp.urlS3Image })
                            .then((respon) => {
                                let chainParams = {
                                    "getPresignedUrl": { sponsor_id, file_type, image_type },
                                    "uploadImage": [files, resp.urlS3Image, file_type],
                                    "updateInspeccion": { task_id: sponsor_id, [`${image_type}`]: resp.urlS3Image, }
                                }
                                return true;
                            })
                    })
            })
    }
}