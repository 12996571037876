import React, { useState } from "react";
import CustomDropMenu from '../custom/customDropMenu'
import { SectionForm, SectionTitle, TwoSectionSplit, PurpleDot, TextInputArea } from './sponsorStylingForm'
import {TSponsorData} from '../sponsorsList/sponsorForm'


interface widgetProps { sponsorData: TSponsorData, updateSponsorAttributes: Function, typeFlag: boolean, updateTypeFlag: Function, dropOptions:any}
export default function SponsorType(props: widgetProps) {
    let { sponsorData, updateSponsorAttributes, typeFlag, updateTypeFlag, dropOptions } = props;
    const changeSponsorType = (value: any) => {updateSponsorAttributes("Type", value)}


    return (
            <SectionForm>
                <SectionTitle>Type <PurpleDot /></SectionTitle>
                <CustomDropMenu selected={sponsorData.Type} options={dropOptions.type} type="simple" updateSelected={changeSponsorType} dropFlag={typeFlag} updateDropFlag={updateTypeFlag} customDisabled={sponsorData.Status} />
            </SectionForm>
            
    );
}