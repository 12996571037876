import React, { useLayoutEffect } from 'react'

import { useAppSelector } from '../app/hooks';
import { selectUserInfo } from '../services/user/userSlice';
import Cookies from 'universal-cookie';
import axios from 'axios';

import './Verifying.css';
import loading from '../assets/gif/loading.gif'
import userNotFound from '../assets/icons/user-not-found.png'

const cookies = new Cookies();

function Verifying() {
    const userInfo = useAppSelector(selectUserInfo);

    useLayoutEffect(() => {
    }, [userInfo])

    return (
        <div className="verifying-main">
            <div className='verifying-checking-session' >

                <img 
                    className={ userInfo.status === 'failed' ? 'verifying-user-notfound' : 'verifying-hidden' }
                    src={ userNotFound }
                />
            
                <p className={ userInfo.status === 'failed' ? 'verifying-checking-title' : 'verifying-hidden' }>
                    Please login with a admin user account at
                    <br/><br/> <a href='https://foundx.smartssi.net/login'> foundx.smartssi.net/login </a>
                </p>

                <p className={ userInfo.status === 'loading' ? 'verifying-checking-title' : 'verifying-hidden' }>
                    Checking current session
                </p>

                <img 
                    className={ userInfo.status === 'loading' ? '' : 'verifying-hidden' }
                    src={loading}
                />
            </div>
        </div>
    )
}

export default Verifying;