import React from "react";
import DefaultSponsor from '../../assets/icons/defaultSponsor.png';
import PenGray from '../../assets/icons/penGray.png';
import { SectionForm, SectionTitle, SponsorImageContainer, ImageInputType, EditButton, EmptyImageContainer, SponsorImage } from './sponsorStylingForm'
import { onFileChange } from '../../services/image/imageToFile'
import {TImageFiles} from '../sponsorsList/sponsorForm'

interface widgetProps { sponsorData: any, updateSponsorAttributes: Function, updateImageFiles: Function, imageFiles:TImageFiles }

export default function Sponsor(props: widgetProps) {

    let { sponsorData, updateSponsorAttributes, updateImageFiles, imageFiles } = props;

    let updateSponsorImage = (event: any) => {
        let sponsorImageUrl = URL.createObjectURL(event.target.files[0])
        updateSponsorAttributes("SponsorImage", sponsorImageUrl)

        onFileChange(event).then((response: any) => {
            let temporalInfo: TImageFiles = { ...imageFiles }
            temporalInfo.SponsorImageData.file = response[0].file;
            temporalInfo.SponsorImageData.url = sponsorImageUrl;
            updateImageFiles(temporalInfo)
        }).catch((error) => {
            console.log("error: " + error)
        })
    }
    let clickSponsor = () => { document.getElementById("SponsorImageFile")?.click() }


    let emptyImage = (
        <EmptyImageContainer onClick={clickSponsor} clickable={true}>
            <img src={DefaultSponsor} alt="EmptyImage" width="128px" height="128px" />
        </EmptyImageContainer>
    )
    let sponsorImage = <SponsorImage src={sponsorData.SponsorImage} alt="SponsorImage" />;

    return (
        <SectionForm>
            <SectionTitle>Sponsor image</SectionTitle>
            <SponsorImageContainer>
                {sponsorData.SponsorImage ? sponsorImage : emptyImage}
                {!sponsorData.SponsorImage ? "" : (
                    <EditButton onClick={clickSponsor} disabled={false} state={false}>
                        Edit sponsor image
                        <img src={PenGray} alt="edit" />
                    </EditButton>
                )}
                <ImageInputType type="file" id="SponsorImageFile" name="SponsorImageFile" onChange={updateSponsorImage} />
            </SponsorImageContainer>
        </SectionForm>
    );
}